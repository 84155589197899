<template>
    <div class="TeamDetails" style="background: #fff;">
        <div style="height: 57px; padding: 0 17px; color: #3C3C3C; font-size: 14px; display: flex; align-items: center; justify-content: space-between; font-weight: 500; ">
            <div style="display: flex; align-items: center;">
                <div>战队人数</div>
                <div style="color: #A0A0A0;">({{teamPlayerList.length}})</div>
            </div>
            <div @click="handleClickAddPlayer" style="color: #4279FC; border: 1px solid #4279FC; font-size: 12px; width: 107px; height: 35px; line-height: 35px; text-align: center; border-radius: 6px;">
                新增队员
            </div>
        </div>

        <div style="height: 520px; overflow-y: auto; font-weight: 400;">
            <div v-for="(item, index) in teamPlayerList" :key="index" style="display: flex; align-items: center; justify-content: space-between; box-sizing: border-box; padding: 18px 18px 12px; margin-bottom: 12px; background: #fff; box-shadow: 0 0 5px #cdcdcd; margin: 10px;">
                <div style="display: flex; align-items: center;">
                    <!-- <div style="width: 53px; height: 53px; background: #4372fc; margin-right: 12px; border-radius: 50%;"></div> -->
                    <img :src="item.avatar" alt="" style="width: 53px; height: 53px; margin-right: 12px; border-radius: 50%;" />
                    <div style="font-size: 12px;">
                        <div style="color: #282728; font-size: 14px;">{{item.realname}}</div>
                        <div style="color: #2D5ADD;">{{item.telphone}}</div>
                        <div style="color: #484848;">微信昵称：{{item.nickname}}</div>
                        <div style="color: #BDBFC0;">{{item.createtime}} 加入</div>
                    </div>
                </div>

                <div @click="handleClickDeletePlayer(item)" style="color: #EB4918; font-size: 14px;">删除</div>
            </div>
        </div>

        <!-- 删除队员提示 --> 
        <div v-if="isShowDeletePlayersHint">
            <div class="mask"></div>
            <div style="z-index: 3000; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 323px; height: 200px; border-radius: 10px; box-sizing: border-box; padding: 18px 29px; background: #fff;">
                <div style="display: flex; align-items: center; justify-content: center; font-size: 16px; margin-top: 45px;">
                    <div style="color: #393939;">确定是否删除队员</div>
                    <div style="color: #4372FC;">"{{this.currentOperationTeamPlayerInfo.realname}}"</div>
                </div>

                <div style="display: flex; align-items: center; justify-content: space-between; position: absolute; left: 0; bottom: 0; box-sizing: border-box; width: 100%; padding: 18px 29px;">
                    <div @click="isShowDeletePlayersHint = false" style="width: 116px; height: 44px; border-radius: 5px; box-sizing: border-box; border: 1px solid #e4e4e4; color: #3E3E3E; font-size: 16px; text-align: center; line-height: 42px;">
                        取 消
                    </div>
                    <div @click="handleConfirmDeleteTeamPlayer" style="width: 116px; height: 44px; border-radius: 5px; box-sizing: border-box; border: 1px solid #E31A1A; color: #E31A1A; font-size: 16px; text-align: center; line-height: 42px;">
                        删 除
                    </div>
                </div>
            </div>
        </div>

        <!-- 新增战员提示 --> 
        <div v-if="isShowAddPlayersHint">
            <div class="mask"></div>
            <div style="z-index: 3000; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 323px; border-radius: 10px; box-sizing: border-box; padding: 18px 29px; background: #fff;">
                <div style="font-size: 16px; color: #393939; text-align: center; font-weight: blod;">新增战队成员</div>
                
                <div style="display: flex; align-items: center; font-size: 15px; margin-top: 25px; font-weight: blod;">
                    <div style="color: #393939; flex-shrink: 0;">战队名称：{{teamName}}</div>
                </div>
                <div style="background: #f5f5f5; border-radius: 10px; box-sizing: border-box; width: 100%; height: 295px; margin: 7px 0 14px; overflow-y: auto;">
                    <div @click="currentSelectAddPlayerIndex = index" v-for="(item, index) in addTeamPlayerList" :key="index" style="height: 60px; display: flex; align-items: center; justify-content: space-between; padding: 0 20px;">
                        <div style="display: flex; align-items: center;">
                            <div style="width: 65px;">{{item.realname}}</div> 
                            <div>{{item.telphone}}</div>
                        </div>
                        <div>
                            <img v-if="currentSelectAddPlayerIndex === index" style="width: 18px; height: 18px;" src="../assets/xuan.png" alt="" />
                            <img v-else style="width: 18px; height: 18px;" src="../assets/wei.png" alt="" />
                        </div>
                    </div>
                </div>

                <div style="height: 44px;"></div>
                <div style="display: flex; align-items: center; justify-content: space-between; position: absolute; left: 0; bottom: 0; box-sizing: border-box; width: 100%; padding: 18px 29px;">
                    <div @click="isShowAddPlayersHint = false" style="width: 116px; height: 44px; color: #CDCDCD; font-size: 16px; text-align: center; line-height: 44px;">
                        取 消
                    </div>
                    <div @click="handleConfirmAddTeamPlayer" style="width: 116px; height: 44px; color: #242F28; font-size: 16px; text-align: center; line-height: 44px;">
                        确认
                    </div>
                </div>
            </div>
        </div>

        <!-- 提示 -->
        <div v-if="isShowHint">
            <div style="z-index: 3001; color: #fff; font-weight: 400; font-size: 12px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: rgba(0, 0, 0, .6); border-radius: 4px; padding: 10px 15px;">
                {{hintContent}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TeamDetails",
    components: {

    },
	data() {
		return {
            // 是否显示删除队员提示
            isShowDeletePlayersHint: false,
            // 是否显示新增队员提示
            isShowAddPlayersHint: false, 
            // 活动id
            id: "",
            // 战队id
            teamId: '',
            // 战队人员列表
            teamPlayerList: [],
            // 新增队员列表
            addTeamPlayerList: [],  
            // 当前操作的战队人员信息
            currentOperationTeamPlayerInfo: {},
            // 是否显示提示
            isShowHint: false,
            // 提示内容
            hintContent: '',
            // 战队名称
            teamName: '',
            // 当前选择的新增队员的下标
            currentSelectAddPlayerIndex: -1,
		}
	},
    mounted() {
        const { id, teamId, title } = this.$route.query;
        this.id = parseInt(id);
        this.teamId = parseInt(teamId);
        this.teamName = title;

        this.getTeamPlayerList();
    },
    methods: {
        /** 获取战队人员列表 */
        getTeamPlayerList() {
            this.$axios.post("/teams/getTeamMember", {
                event_id: this.id,
                team_id: this.teamId,
            }).then(res => {
                this.teamPlayerList = res.data;
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 获取新增队员列表 */
        getAddTeamPlayerList() {
            this.$axios.post("/teams/getTeamApplyList", {
                event_id: this.id,
                team_id: this.teamId,
            }).then(res => {
                this.addTeamPlayerList = res;
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 处理点击删除队员 */
        handleClickDeletePlayer(item) {
            this.currentOperationTeamPlayerInfo = item;
            this.isShowDeletePlayersHint = true;
        },

        /** 处理确认删除队员操作 */
        handleConfirmDeleteTeamPlayer() {
            this.$axios.post("/teams/delTeamMember", {
                team_id: this.teamId,
                member_id: this.currentOperationTeamPlayerInfo.member_id,
                event_id: this.id,
            }).then(res => {
                this.isShowDeletePlayersHint = false;
                this.showMessage(res === undefined ? "删除成功" : res);
                this.getTeamPlayerList();
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 处理点击新增队员 */
        handleClickAddPlayer() {
            this.currentSelectAddPlayerIndex = -1;
            this.addTeamPlayerList = [];
            this.getAddTeamPlayerList();
            this.isShowAddPlayersHint = true;
        },

        /** 处理确认添加新增队员 */
        handleConfirmAddTeamPlayer() {
            if(this.currentSelectAddPlayerIndex === -1) {
                return;
            }

            this.$axios.post("/teams/joinTeam", {
                team_id: this.teamId,
                team_admin_id: this.addTeamPlayerList[this.currentSelectAddPlayerIndex].id,
                event_id: this.id,
            }).then(res => {
                this.isShowAddPlayersHint = false;
                this.showMessage(res === undefined ? "加入成功" : res);
                this.getTeamPlayerList();
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 显示提示 */
        showMessage(message) {
            this.hintContent = message;
            this.isShowHint = true;
            const timeout = setTimeout(() => {
            this.isShowHint = false;
                clearTimeout(timeout);
            }, 1000);
        },
    }
};
</script>

<style scoped lang="scss">
    .TeamDetails {
        background: #f5f5f5;
        min-height: 100vh;
        height: 100%;
    }

    .input-style-none {
        ::v-deep input {
            background: #f8f8f8;
            border: none;
        }
    }

    .input-date {
        ::v-deep {
            input {
                padding: 0 5px;
                font-size: 12px;
                text-align: center;
                height: 33px;
                line-height: 33px;
            }
            .el-input__prefix {
                display: none;
            }
        }
    }

    .el-input {
        ::v-deep input {
            border: none !important;
            padding: 0;
        }
    }
</style>
